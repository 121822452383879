import AmountKpisDashboard from '../components/cellRenderers/AmountKpisDashboard';
import AmountWithGrowth from '../components/cellRenderers/AmountWithGrowth';

import Filters from '../components/Filters';
import Grid from '../components/Grid';

export default {
  components: {
    AmountKpisDashboard,
    AmountWithGrowth,
    Filters,
    Grid,
  },
  methods: {
    calculateGrowth(past, current) {
      return Math.abs((past - current) / past) * (current < past ? -1 : 1);
    },
    withLeadingZero(number) {
      return `0${number}`.slice(-2);
    },
    formatKpi(rawValue, kpi, isDiff = false) {
      if (!rawValue) {
        return undefined;
      }

      const isPercentKpis = [
        'install to trial',
        'roas (%)',
        'trial to paid',
      ].includes(kpi.toLowerCase());
      const isMoneyKpis = [
        'arpes',
        'arpns',
        'arps',
        'cpa',
        'cpi',
        'revenue spu',
        'revenue snu',
        'revenue sau',
        'roas (eur)',
        'spent',
      ].includes(kpi.toLowerCase());
      const precision = (isPercentKpis || (isMoneyKpis && Math.abs(rawValue) < 100)) ? 2 : 0;
      const suffix = (isPercentKpis && isDiff && ' bps')
        || (isPercentKpis && ' %')
        || (isMoneyKpis && ' €')
        || '';

      const value = isPercentKpis
        ? rawValue * 100
        : rawValue;
      const amount = value
        .toFixed(precision)
        .replace(/\.0+$/, '')
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

      return `${amount} ${suffix}`.trim();
    },
  },
};
