import gql from 'graphql-tag';

export const GET_REVENUES = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String, $isVariantProduct: Boolean!, $isVariantCountry: Boolean!) {
    revenuesByApp(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appId
      appName
      appSku
      proceeds
      month
      year
      weekOfYear @include(if: $weekly)
      country @include(if: $isVariantCountry)
      productSku @include(if: $isVariantProduct)
      productName @include(if: $isVariantProduct)
      ads @skip(if: $isVariantProduct)
    }
  }
`;

export const GET_LTV = gql`
  query ($from: String!, $to: String!, $variant: String, $isWeekly: Boolean!) {
    ltvByApp(from: $from, to: $to, variant: $variant, weekly: $isWeekly) {
      appName
      month
      year
      country
      productName
      nbTrials
      ltv3yLow
      ltv3y
      ltv3yUp
      initialLtvLow
      initialLtv
      initialLtvUp
      momEvol
      cpa
      breakeven
    }
  }
`;

export const GET_MARGINS = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String, $isVariantCountry: Boolean!) {
    marginsByApp(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appId
      appName
      month
      year
      weekOfYear @include(if: $weekly)
      country @include(if: $isVariantCountry)
      ads
      proceeds
      spent
    }
  }
`;

export const GET_PREDICTIVE_MARGINS = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String, $isVariantCountry: Boolean!) {
    predictiveMarginByApp(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appId
      appName
      month
      year
      weekOfYear @include(if: $weekly)
      country @include(if: $isVariantCountry)
      margin1y
      margin2y
      margin3y
    }
  }
`;

export const GET_PREDICTIVE_ROAS = gql`
  query ($from: String!, $to: String!, $variant: String) {
    predictiveRoasByApp(from: $from, to: $to, variant: $variant) {
      appId
      appName
      month
      year
      country
      revenues1y
      roas1y
      revenues2y
      roas2y
      revenues3y
      roas3y
      spent
    }
  }
`;

export const GET_PREDICTIVE_ROAS_COUNTRY = gql`
  query ($from: String!, $to: String!) {
    predictiveRoasByCountryOnly(from: $from, to: $to) {
      month
      year
      country
      appId
      appName
      revenues1y
      roas1y
      revenues2y
      roas2y
      revenues3y
      roas3y
      spent
    }
  }
`;

export const GET_PREDICTIVE_ROAS_APP = gql`
  query ($from: String!, $to: String!) {
    predictiveRoasByAppOnly(from: $from, to: $to) {
      appId
      appName
      month
      year
      revenues1y
      roas1y
      revenues2y
      roas2y
      revenues3y
      roas3y
      spent
    }
  }
`;

export const GET_ROI = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String) {
    roiByApp(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appId
      appName
      month
      year
      country
      roi
      ads
      proceeds
      spent
      weekOfYear @include(if: $weekly)
    }
  }
`;

export const GET_SPENT = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String, $isVariantCountry: Boolean!, $isVariantChannel: Boolean!) {
    spentByApp(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appId
      appName
      appSku
      amount
      month
      year
      weekOfYear @include(if: $weekly)
      country @include(if: $isVariantCountry)
      channelName @include(if: $isVariantChannel)
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  query ($from: String!, $to: String!, $weekly: Boolean!) {
    dashboardData(from: $from, to: $to, weekly: $weekly) {
      appId
      appName
      country
      roas3y
      spent
      trials
      ltv3yeur
      revenue3yeur
      cpa
    }
  }
`;

export const GET_APPS_DASHBOARD_DATA = gql`
  query ($from: String!, $to: String!, $weekly: Boolean!) {
    appsDashboardData(from: $from, to: $to, weekly: $weekly) {
      appName
      currentYear
      currentMonth
      country
      kpi
      monthlyData {
        month
        value
        monthOverMonth
        monthOverMonthPercent
      }
    }
  }
`;

export const GET_MONTH_TO_DATE = gql`
  query ($from: String, $to: String) {
    monthToDateData(from: $from, to: $to) {
      appName
      year
      month
      country
      kpi
      target
      actual
      diff
      diffPercent
      mtd1
      m1
      mtd1diff
      mtd1percdiff
      mtd2
      m2
      mtd2diff
      mtd2percdiff
      mtd3
      m3
      mtd3diff
      mtd3percdiff
      mtd4
      m4
      mtd4diff
      mtd4percdiff
      mtd5
      m5
      mtd5diff
      mtd5percdiff
      mtd6
      m6
      mtd6diff
      mtd6percdiff
      mtd7
      m7
      mtd7diff
      mtd7percdiff
      mtd8
      m8
      mtd8diff
      mtd8percdiff
      mtd9
      m9
      mtd9diff
      mtd9percdiff
      mtd10
      m10
      mtd10diff
      mtd10percdiff
      mtd11
      m11
      mtd11diff
      mtd11percdiff
    }
  }
`;

export const GET_STUDIO_PERF = gql`
  query ($from: String, $to: String) {
    studioPerfData(from: $from, to: $to) {
      studio
      appName
      currentYear
      currentMonth
      kpi
      monthlyData {
        month
        value
        target
        targetDiff
        targetDiffPercent
      }
    }
  }
`;

export const GET_CASHFLOW = gql`
  query ($weekly: Boolean!, $from: String!, $to: String!, $variant: String, $isVariantCountry: Boolean!, $isVariantProduct: Boolean!) {
    cashflowByApp(weekly: $weekly, from: $from, to: $to, variant: $variant) {
      appId
      appName
      country @include(if: $isVariantCountry)
      year
      month
      weekOfYear @include(if: $weekly)
      rebillDate
      incomeeur
      inferred
      productSku @include(if: $isVariantProduct)
      productName @include(if: $isVariantProduct)
    }
  }
`;

export const GET_WEEKLY_PERF = gql`
  query ($from: String, $to: String) {
    weeklyPerfData(from: $from, to: $to) {
      appName
      country
      kpi
      weeklyData {
        week
        date
        value
        target
        targetDiff
        targetDiffPercent
        weekOverWeek
        weekOverWeekPercent
        dailyValueW
      }
    }
  }
`;
